import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
// import { setIsAdninLogin } from "../redux/actions/auth";
import { getAuthLocalStorage } from "../storage/getLocalStorage";
// import { useDispatch, useSelector } from "react-redux";
// import AppLoader from "../libComponents/AppLoader/AppLoader";
import Layout from "../component/Layout";
import Login from "../pages/Login";
import Dashboard from "../pages/Dashboard";
import Users from "../pages/Users";
import Team from "../pages/Team";
import Products from "../pages/Products";
import SubCategory from "../pages/SubCategory";
import ProductList from "../pages/ProductList";
import Technologies from "../pages/Technologies";
import Clients from "../pages/Clients";
import Testimonial from "../pages/Testimonials";
import CareerOpenings from "../pages/CareerOpenings";
import AppliedJobs from "../pages/AppliedJobs";
import Events from "../pages/Events";
import Banner from "../pages/Banner";
import Notification from "../pages/Notification";
import OrderList from "../pages/OrderList";
import ProjectMetrics from "../pages/ProjectMetrics";
import Cart from "../pages/Cart";
import Contact from "../pages/ContactUs";
import ChangePassword from "../pages/ChangePassword";
import EditProfile from "../pages/EditProfile";
import NewPassword from "../pages/NewPassword";
import ChangeOldPassword from "../pages/ChangeOldPassword";
import WarnedUser from "../pages/WarnedUsers";
import SuspendedUsers from "../pages/SuspendedUsers";
import CodeMania from "../pages/CodeMania";
import Component from "../pages/Component/index";
import AddComponent from "../pages/Component/AddComponent";
import ListComponents from "../pages/Component/ListComponents";
import EditComponent from "../pages/Component/EditComponents";
import AddContest from "../pages/CodeMania/contests/AddContest";
import ContestList from "../pages/CodeMania/contests/ListContest";
import EditContest from "../pages/CodeMania/contests/EditContest";
import AddQuiz from "../pages/CodeMania/quiz/AddQuiz";
import ListQuiz from "../pages/CodeMania/quiz/QuizList";
import EditQuiz from "../pages/CodeMania/quiz/QuizEdit";
import AddGiveaway from "../pages/CodeMania/giveAway/AddGiveAway";
import GiveawayList from "../pages/CodeMania/giveAway/ListGiveAway";
import EditGiveaway from "../pages/CodeMania/giveAway/EditGiveAway";
import AddPoll from "../pages/CodeMania/polls/AddPolls";
import PollList from "../pages/CodeMania/polls/ListPolls";
import EditPoll from "../pages/CodeMania/polls/EditPolls";
import AddQAPage from "../pages/CodeMania/Qna/AddQna";
import EditQAPage from "../pages/CodeMania/Qna/EditQna";
import QAListPage from "./../pages/CodeMania/Qna/QnaList";
import AddDoItBetterPage from "../pages/CodeMania/doItBetter/AddDoItBetter";
import ListDoItBetterPage from "../pages/CodeMania/doItBetter/ListDoItBetter";
import EditDoItBetterPage from "../pages/CodeMania/doItBetter/EditDoItBetter";

/**
 * @author Lovesh Singh
 * @since 10-08-2023
 * @description app containter for wrapping all routes
 * @returns JSX.Element
 */
function AppContainer() {
  // const dispatch = useDispatch();
  // const { loading } = useSelector((state) => state.adminReducer);

  /**
   * @author Lovesh Singh
   * @since 12-10-2023
   * @description to render layout component
   * @returns JSX.Element
   */
  const renderLayout = (children) => {
    return <Layout>{children}</Layout>;
  };

  useEffect(() => {
    // getAuthLocalStorage().then((res) => {
    //   // dispatch(setIsAdninLogin(res.isLoggedIn));
    // });
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={renderLayout(<Dashboard />)} />
        <Route path="/users" element={renderLayout(<Users />)} />
        <Route path="/team" element={renderLayout(<Team />)} />
        <Route path="/categories" element={renderLayout(<Products />)} />
        <Route path="/subcategories" element={renderLayout(<SubCategory />)} />
        <Route path="/productlist" element={renderLayout(<ProductList />)} />
        <Route path="/technologies" element={renderLayout(<Technologies />)} />
        <Route path="/CodeMania" element={renderLayout(<CodeMania />)} />
        <Route path="/Component" element={renderLayout(<AddComponent />)} />
        <Route
          path="/Component/contest/add"
          element={renderLayout(<AddContest />)}
        />
        <Route
          path="/Component/contest/list"
          element={renderLayout(<ContestList />)}
        />
        <Route
          path="/Component/contest/edit/:id"
          element={renderLayout(<EditContest />)}
        />
         <Route
          path="/Component/doItBetter/add"
          element={renderLayout(<AddDoItBetterPage />)}
        />
        <Route
          path="/Component/doItBetter/list"
          element={renderLayout(<ListDoItBetterPage />)}
        />
        <Route
          path="/Component/doItBetter/edit/:id"
          element={renderLayout(<EditDoItBetterPage />)}
        />
        <Route
          path="/Component/polls/add"
          element={renderLayout(<AddPoll />)}
        />
        <Route
          path="/Component/polls/list"
          element={renderLayout(<PollList />)}
        />
        <Route
          path="/Component/polls/edit/:id"
          element={renderLayout(<EditPoll />)}
        />
        <Route
          path="/Component/questions/add"
          element={renderLayout(<AddQAPage />)}
        />
        <Route
          path="/Component/questions/list"
          element={renderLayout(<QAListPage />)}
        />
        <Route
          path="/Component/questions/edit/:id"
          element={renderLayout(<EditQAPage />)}
        />
        <Route
          path="/Component/giveaway/add"
          element={renderLayout(<AddGiveaway />)}
        />
        <Route
          path="/Component/giveaway/list"
          element={renderLayout(<GiveawayList />)}
        />
        <Route
          path="/Component/giveaway/edit/:id"
          element={renderLayout(<EditGiveaway />)}
        />
        <Route path="/Component/quiz/add" element={renderLayout(<AddQuiz />)} />
        <Route
          path="/Component/quiz/list"
          element={renderLayout(<ListQuiz />)}
        />
        <Route
          path="/Component/quiz/edit/:id"
          element={renderLayout(<EditQuiz />)}
        />
        <Route
          path="/Component/list"
          element={renderLayout(<ListComponents />)}
        />
        <Route
          path="/Component/edit/:id"
          element={renderLayout(<EditComponent />)}
        />
        <Route path="/clients" element={renderLayout(<Clients />)} />
        <Route path="/testimonials" element={renderLayout(<Testimonial />)} />
        <Route
          path="/careeropenings"
          element={renderLayout(<CareerOpenings />)}
        />
        <Route path="/AppliedJobs" element={renderLayout(<AppliedJobs />)} />
        <Route path="/events" element={renderLayout(<Events />)} />
        <Route path="/banner" element={renderLayout(<Banner />)} />
        <Route path="/notification" element={renderLayout(<Notification />)} />
        <Route path="/orderhistory" element={renderLayout(<OrderList />)} />
        <Route
          path="/projectmetrics"
          element={renderLayout(<ProjectMetrics />)}
        />
        <Route path="/cart" element={renderLayout(<Cart />)} />
        <Route path="/contactus" element={renderLayout(<Contact />)} />
        <Route path="/editprofile" element={renderLayout(<EditProfile />)} />
        <Route
          path="/changeoldpassword"
          element={renderLayout(<ChangeOldPassword />)}
        />
        <Route path="/warnedusers" element={renderLayout(<WarnedUser />)} />
        <Route
          path="/suspendedusers"
          element={renderLayout(<SuspendedUsers />)}
        />
        <Route path="/login" element={<Login />} />
        <Route path="/changepassword" element={<ChangePassword />} />
        <Route path="/newpassword" element={<NewPassword />} />
      </Routes>
      {/* <AppLoader show={loading} /> */}
    </>
  );
}

export default AppContainer;
