import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { APIUrl } from "../../Network/networkConfiguration";
import { toast } from "react-toastify";

export default function AddComponent() {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [code, setCode] = useState("");
  const [componentType, setComponentType] = useState(""); // Selected componentType
  const [componentTypes, setComponentTypes] = useState([]); // List of component types
  const [newComponentType, setNewComponentType] = useState(""); // New componentType input for adding
  const navigate = useNavigate();

  // Fetch component types from the API on mount
  useEffect(() => {
    const fetchComponentTypes = async () => {
      try {
        const response = await axios.get(`${APIUrl}distinct-component`);
        console.log("djjdj",response)
        setComponentTypes(response.data.data); // Assuming the API response contains an array of types
      } catch (error) {
        console.error("Error fetching component types:", error);
        toast.error("Failed to fetch component types");
      }
    };
    fetchComponentTypes();
  }, []);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Ensure all fields are filled
    if (!name || !description || !code || !componentType) {
      toast.error("All fields are required");
      return;
    }

    const data = {
      name,
      description,
      code,
      componentType,
    };

    try {
      const response = await axios.post(`${APIUrl}add-component`, data);

      if (response) {
        toast.success("Component created successfully");
        navigate("/Component/list");
      } else {
        toast.error("Failed to create component");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to create component");
    }
  };

  // Handle adding new component type
  const handleAddNewComponentType = () => {
    console.log(newComponentType,"jj")
    if (newComponentType) {
      setComponentTypes([...componentTypes, newComponentType]); // Add new type to the list
      setComponentType(newComponentType); // Set it as the selected value
      setNewComponentType(""); // Clear the input field
      toast.success("New component type added");
    }
  };

  // Redirect to component list
  const handleredirect = () => {
    navigate(`/Component/list`);
  };

  return (
    <div className="container relative mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Add New Component</h1>
      <div className="absolute right-4 top-5">
        <button onClick={handleredirect}>
          <h3 className="p-2 bg-blue-500 rounded-xl px-5 text-white">List</h3>
        </button>
      </div>
      <form onSubmit={handleSubmit}>
        {/* Component Type Selector */}
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Component Type
          </label>
          <select
            value={componentType}
            onChange={(e) => setComponentType(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
          >
            <option value="" disabled>
              Select component type
            </option>
            {componentTypes?.map((type, index) => (
              <option className="text-black" key={index} value={type}>
                {type}
              </option>
            ))}
            <option value="add-new-type">Add New Type</option>
          </select>
        </div>

        {/* Add New Component Type Input (shows only when 'Add New Type' is selected) */}
        {componentType === "add-new-type" && (
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              New Component Type
            </label>
            <input
              type="text"
              value={newComponentType}
              onChange={(e) => setNewComponentType(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded"
              placeholder="Enter new component type"
            />
            <button
              type="button"
              onClick={handleAddNewComponentType}
              className="mt-2 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
            >
              Add Component Type
            </button>
          </div>
        )}

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Name
          </label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            placeholder="Enter modal name"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Description
          </label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            placeholder="Enter modal description"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Code
          </label>
          <textarea
            value={code}
            onChange={(e) => setCode(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            placeholder="Enter modal code"
            required
          />
        </div>

        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Submit
        </button>
      </form>
    </div>
  );
}
