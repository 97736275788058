import axios from "axios";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { APIUrl } from "../../../Network/networkConfiguration";

const EditDoItBetterPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    language: "",
    topic: "",
    description: "",
    code: "",
  });

  useEffect(() => {
    // Fetch data by ID
    const fetchData = async () => {
      const result = await axios.get(`${APIUrl}doItBetter/${id}`);
      console.log(result,"result ")
      setFormData(result.data.data);
    };
    fetchData();
  }, [id]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    // Call API to update
    await axios.put(`${APIUrl}doItBetter`, {...formData,id:id});

    navigate("/Component/doItBetter/list"); // After update, navigate to the list page
  };

  return (
    <div className="max-w-3xl p-4">
      <h1 className="text-3xl font-bold mb-4">Edit Do it Better</h1>
      <form onSubmit={handleUpdate}>
        <div className="mb-4">
          <label className="block text-sm">Language:</label>
          <select
            name="language"
            value={formData.language}
            onChange={handleChange}
            className="w-full p-2 border rounded"
            required
          >
            <option value="">Select Language</option>
            <option value="React">React</option>
            <option value="Node">Node</option>
            <option value="React Native">React Native</option>
            <option value="JavaScript">JavaScript</option>
          </select>
        </div>

        <div className="mb-4">
          <label className="block text-sm">Topic:</label>
          <input
            name="topic"
            value={formData.topic}
            onChange={handleChange}
            className="w-full p-2 border rounded"
            type="text"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm">Description:</label>
          <textarea
            name="description"
            value={formData.description}
            onChange={handleChange}
            className="w-full p-2 border rounded"
            rows="3"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm">Code:</label>
          <textarea
            name="code"
            value={formData.code}
            onChange={handleChange}
            className="w-full p-2 border rounded"
            rows="5"
            required
          />
        </div>

        <button
          type="submit"
          className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600"
        >
          Update
        </button>
      </form>
    </div>
  );
};

export default EditDoItBetterPage;
