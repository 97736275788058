import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { APIUrl } from "../../Network/networkConfiguration";

export default function EditComponent() {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [code, setCode] = useState("");
  const [componentType, setComponentType] = useState("");
  const [componentTypes, setComponentTypes] = useState([]); // To store available component types
  const [newType, setNewType] = useState(""); // To handle the input for new component type
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${APIUrl}component/${id}`);

        if (response) {
          const componentData = response.data.data;
          setName(componentData.name);
          setCode(componentData.code);
          setDescription(componentData.description);
          setComponentType(componentData.componentType); // Set existing component type
        } else {
          toast.error("Failed to fetch component data");
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error("Failed to fetch component data");
      }
    };

    // Fetch available component types
    const fetchComponentTypes = async () => {
      try {
        const typesResponse = await axios.get(`${APIUrl}distinct-component`);
        setComponentTypes(typesResponse.data.data); // Assume API returns an array of types
      } catch (error) {
        console.error("Error fetching component types:", error);
      }
    };

    fetchData();
    fetchComponentTypes();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      name: name,
      description: description,
      code: code,
      componentType: componentType || newType, // If newType is added, use that
    };

    try {
      const response = await axios.put(`${APIUrl}edit-component/${id}`, data);

      if (response) {
        toast.success("Component updated successfully");
        navigate("/Component/list");
      } else {
        toast.error("Failed to update component");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to update component");
    }
  };

  const handleAddNewType = () => {
    if (newType.trim() && !componentTypes?.includes(newType)) {
      setComponentTypes([...componentTypes, newType]);
      setComponentType(newType); // Automatically select the new type
      setNewType(""); // Clear the input
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Edit Component</h1>
      <form onSubmit={handleSubmit}>
        {/* Component Type Selector */}
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Component Type
          </label>
          <div className="flex">
            <select
              value={componentType}
              onChange={(e) => setComponentType(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded mr-2"
            >
              <option value="">Select Component Type</option>
              {componentTypes?.map((type, index) => (
                <option key={index} value={type}>
                  {type}
                </option>
              ))}
            </select>
            <input
              type="text"
              value={newType}
              onChange={(e) => setNewType(e.target.value)}
              placeholder="Add new type"
              className="w-full p-2 border border-gray-300 rounded"
            />
            <button
              type="button"
              onClick={handleAddNewType}
              className="ml-2 bg-green-500 text-white px-4 py-2 rounded"
            >
              Add
            </button>
          </div>
        </div>

        {/* Name Input */}
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Name
          </label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            placeholder="Enter component name"
          />
        </div>

        {/* Description Input */}
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Description
          </label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            placeholder="Enter component description"
          />
        </div>

        {/* Code Input */}
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Code
          </label>
          <textarea
            value={code}
            onChange={(e) => setCode(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            placeholder="Enter component code"
          />
        </div>

        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Update
        </button>
      </form>
    </div>
  );
}
