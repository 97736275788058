import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { APIUrl } from "../../../Network/networkConfiguration";
import { toast } from "react-toastify";

const AddDoItBetterPage = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    language: "",
    topic: "",
    description: "",
    code: "",
  });

  const languages = ["React", "Node", "React Native", "JavaScript"];

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        await axios.post(`${APIUrl}doItBetter`, formData);
        toast.success("DoItBetter created successfully");
        navigate("/Component/doItBetter/list"); 
      
      } catch (error) {
        toast.error("Failed to create ");
      }
    // After submission, navigate to the list page
  };

  const handleredirect = ()=>{
    navigate("/Component/doItBetter/list");
  }

  return (
    <div className="max-w-3xl  p-4">
      <h1 className="text-3xl font-bold mb-4">Do It Better</h1>
      <div className="absolute right-4 top-28">
        <button onClick={handleredirect}>
          <h3 className="p-2 bg-blue-500 rounded-xl px-5 text-white">List</h3>
        </button>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-sm">Language:</label>
          <select
            name="language"
            value={formData.language}
            onChange={handleChange}
            className="w-full p-2 border rounded"
            required
          >
            <option value="">Select Language</option>
            {languages.map((lang, idx) => (
              <option key={idx} value={lang}>
                {lang}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-4">
          <label className="block text-sm">Topic:</label>
          <input
            name="topic"
            value={formData.topic}
            onChange={handleChange}
            className="w-full p-2 border rounded"
            type="text"
            placeholder="Topic"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm">Description:</label>
          <textarea
            name="description"
            value={formData.description}
            onChange={handleChange}
            className="w-full p-2 border rounded"
            rows="3"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm">Code:</label>
          <textarea
            name="code"
            value={formData.code}
            onChange={handleChange}
            className="w-full p-2 border rounded"
            rows="5"
            required
          />
        </div>

        <button
          type="submit"
          className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default AddDoItBetterPage;
