import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { APIUrl } from "../../../Network/networkConfiguration";
import ResultsModalQA from "./ResultModalQA";

const QAListPage = () => {
  const [qas, setQAs] = useState([]);
  const [users,setUsers] = useState()
  const [showUserModal, setShowUserModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedQA, setSelectedQA] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchQAs = async () => {
      try {
        const response = await axios.get(`${APIUrl}questions`);
        setQAs(response.data.data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchQAs();
  }, []);

  const handleDelete = async (id) => {
    try {
      const res = await axios.delete(`${APIUrl}question/${id}`);
      if (res) {
      }
      try {
        const response = await axios.get(`${APIUrl}questions`);
        setQAs(response.data.data);
      } catch (error) {
        console.error("Error:", error);
      }
      setShowDeleteModal(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const onEdit = (id) => {
    navigate(`/Component/questions/edit/${id}`);
  };

  const showUsers = (qa) => {
    setSelectedQA(qa);
    setUsers(qa?.userVoted);
    setShowUserModal(true);
  };

  const handleredirect = () => {
    navigate(`/Component/questions/add`);
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold">Q/A List</h1>
      <div className="absolute right-4 top-20">
        <button onClick={handleredirect}>
          <h3 className="p-2 bg-blue-500 rounded-xl px-5 text-white">Add</h3>
        </button>
      </div>
      <table className="min-w-full table-auto mt-4">
        <thead>
          <tr>
            <th className="px-4 py-2">Question</th>
            <th className="px-4 py-2">Options</th>
            <th className="px-4 py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {qas?.map((qa) => (
            <tr key={qa._id}>
              <td className="border px-4 py-2">{qa?.question}</td>
              <td className="border px-4 py-2">
                {qa?.options.map((option) => (
                  <div key={option?._id}>
                    {option?.text} {option?.isCorrect && "(Correct)"}
                  </div>
                ))}
              </td>
              <td className="border px-4 py-2">
                <button
                  className="mr-2 bg-yellow-500 text-white p-1 rounded"
                  onClick={() => onEdit(qa?._id)}
                >
                  Edit
                </button>
                <button
                  className="mr-2 bg-red-500 text-white p-1 rounded"
                  onClick={() => {
                    setSelectedQA(qa?._id);
                    setShowDeleteModal(true);
                  }}
                >
                  Delete
                </button>
                <button
                  className="mr-2 bg-blue-500 text-white p-1 rounded"
                  onClick={() => showUsers(qa)}
                >
                  View Users
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {showDeleteModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-4 rounded">
            <h2 className="text-lg font-bold">
              Are you sure you want to delete?
            </h2>
            <div className="mt-4 flex justify-end">
              <button
                className="bg-red-500 text-white p-2 rounded mr-2"
                onClick={() => handleDelete(selectedQA)}
              >
                Delete
              </button>
              <button
                className="bg-gray-500 text-white p-2 rounded"
                onClick={() => setShowDeleteModal(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

{showUserModal && (
       <ResultsModalQA 
       isOpen={showUserModal} 
       onClose={() => setShowUserModal(false)} 
       users={users} 
     />
      )}
    </div>
  );
};

export default QAListPage;
