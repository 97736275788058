import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { APIUser } from "../../../Network/networkConfiguration";
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from "react-syntax-highlighter/dist/esm/styles/prism";

const ListDoItBetterPage = () => {
  const navigate = useNavigate();
  const [listData, setListData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);

  useEffect(() => {
    const fetchPolls = async () => {
      try {
        const response = await axios.get(`${APIUser}doItBetter`);
        setListData(response?.data?.data);
      } catch (error) {
        toast.error("Failed to load data");
      }
    };
    fetchPolls();
  }, []);

  const truncate = (str, n) => (str?.length > n ? str?.slice(0, n) + "..." : str);

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete?")) {
      setListData(listData.filter((item) => item._id !== id));
    }
  };

  const handleEdit = (id) => {
    navigate(`/Component/doItBetter/edit/${id}`);
  };

  const handleLongText = (item) => {
    setSelectedItem(item);
    setShowModal(true);
  };

  const handleUserDetails = (user) => {
    setSelectedUser(user);
    setShowUserModal(true);
  };

  return (
    <div className="max-w-5xl mx-auto p-4">
      <h1 className="text-2xl mb-4">List of Items</h1>
      <table className="w-full border-collapse border">
        <thead>
          <tr>
            <th>S.No</th>
            <th>Language</th>
            <th>Topic</th>
            <th>Description</th>
            <th>Code</th>
            <th>Actions</th>
            <th>Users</th>
          </tr>
        </thead>
        <tbody>
          {listData?.map((item, index) => (
            <tr key={item._id} className="border-b">
              <td>{index + 1}</td>
              <td>{item?.language}</td>
              <td>{item?.topic}</td>
              <td onClick={() => handleLongText(item)}>
                {truncate(item?.description, 20)}
                {item?.description?.length > 20 && <button>...</button>}
              </td>
              <td onClick={() => handleLongText(item)}>
                {truncate(item?.code, 20)}
                {item?.code?.length > 20 && <button>...</button>}
              </td>
              <td className="gap-3">
                <button onClick={() => handleEdit(item?._id)}>✏️</button>
                <button className="ml-3" onClick={() => handleDelete(item?._id)}>🗑️</button>
              </td>
              <td>
                {item.users?.map((user, idx) => (
                  <button
                    key={user.id._id}
                    onClick={() => handleUserDetails(user)}
                    className="ml-2"
                  >
                    👁️ {user.id.firstName}
                  </button>
                ))}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Item Details Modal */}
      {showModal && selectedItem && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center p-4">
          <div className="bg-white p-6 max-h-[500px] rounded-lg shadow-xl w-full max-w-3xl">
            <h2 className="text-2xl font-bold mb-4">Details</h2>
            <div className="mb-4">
              <strong>Description:</strong> {selectedItem?.description}
            </div>
            <div className="mb-4">
              <strong>Code:</strong>
              <div className="mt-2 border rounded">
                <SyntaxHighlighter
                  language="jsx"
                  style={oneDark}
                  customStyle={{
                    padding: "1rem",
                    borderRadius: "0.375rem",
                    maxHeight: "280px",
                  }}
                  wrapLines={true}
                  wrapLongLines={true}
                >
                  {selectedItem?.code}
                </SyntaxHighlighter>
              </div>
            </div>
            <button
              onClick={() => setShowModal(false)}
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/* User Details Modal */}
      {showUserModal && selectedUser && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center p-4">
          <div className="bg-white p-6 max-h-[500px] rounded-lg shadow-xl w-full max-w-3xl">
            <h2 className="text-2xl font-bold mb-4">User Details</h2>
            <div className="mb-4">
              <strong>Name:</strong> {selectedUser.id.firstName} {selectedUser.id.lastName}
            </div>
            <div className="mb-4">
              <strong>Email:</strong> {selectedUser.id.email}
            </div>
            <div className="mb-4">
              <strong>Phone:</strong> {selectedUser.id.phone}
            </div>
            <div className="mb-4">
              <strong>Submitted Code:</strong>
              <div className="mt-2 border rounded">
                <SyntaxHighlighter
                  language="html"
                  style={oneDark}
                  customStyle={{
                    padding: "1rem",
                    borderRadius: "0.375rem",
                    maxHeight: "280px",
                  }}
                  wrapLines={true}
                  wrapLongLines={true}
                >
                  {selectedUser.submitted}
                </SyntaxHighlighter>
              </div>
            </div>
            <button
              onClick={() => setShowUserModal(false)}
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ListDoItBetterPage;
