import React from 'react';
import { X } from 'lucide-react';

const ResultsModalQA = ({ isOpen, onClose, users }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-4xl max-h-[80vh] overflow-hidden">
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-xl font-semibold">Users and their answers</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X size={24} />
          </button>
        </div>
        <div className="p-4 overflow-auto max-h-[calc(80vh-8rem)]">
          <table className="w-full">
            <thead>
              <tr className="bg-gray-100">
                <th className="p-2 text-center">Name</th>
                <th className="p-2 text-center">Email</th>
                <th className="p-2 text-center">Phone</th>
                <th className="p-2 text-center">Question </th>
                <th className="p-2 text-center">Answer </th>
                <th className="p-2 text-center">Correct </th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, index) => (
                <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                  <td className="p-2">{`${user?.user?.firstName} ${user?.user?.lastName}`}</td>
                  <td className="p-2">{user?.user?.email}</td>
                  <td className="p-2">{user?.user?.phone}</td>
                  <td className="p-2">{user?.questionId}</td>
                  <td className="p-2">{user?.optionId}</td>
                  <td className="p-2">{user?.isCorrect ?"Yes":"No"}</td>
                 
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ResultsModalQA;